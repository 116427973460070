import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useInView } from "react-intersection-observer";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

// SEO
import { PageSeo } from "../components/global/page-seo";

// Components
import { DetermineModule } from "../components/utils/determine-module";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Components
import { ListArticles } from "../components/press/list-articles";
// import { ListActivities } from "../components/activities/list-activities";

// Context
import { CurrentLanguage } from "../components/context/current-language";
import { HeaderColor } from "../components/context/header-color";
import { PageColor } from "../components/context/page-color";
import { PageContext } from "../components/context/page-context";

const Page = styled.div`
  background-color: #efefef;

  /* & .dropdown-container {
    margin: 0 0 100px 0;

    & .project-filter-select {
      max-width: 390px;
      width: 100%;

      & .project-filter__control,
      & .project-filter__menu {
        border-color: #09184f;
        border-radius: 0;
        background-color: #efefef;
        box-shadow: none;
      }

      & .project-filter__placeholder {
        color: #09184f;
      }

      & .project-filter__indicator-separator {
        display: none;
      }

      & .project-filter__indicator {
        color: #09184f;
      }

      & .project-filter__menu {
        border: 1px solid #09184f;
        margin: 0;
      }

      & .project-filter__menu-list {
        padding: 0;
      }

      & .project-filter__option--is-focused,
      & .project-filter__option--is-selected {
        background-color: #09184f;
        color: #fff;
      }
    }

    @media (max-width: 800px) {
      margin: 0 0 90px 0;
    }

    @media (max-width: 767px) {
      padding: 0 25px;
    }
  } */
`;

const Introduction = styled.div`
  &.articles-view {
    padding: 100px 40px 90px 40px;

    @media (max-width: 800px) {
      padding: 31px 25px 50px 25px;
    }
  }

  &.activities-view {
    padding: 151px 40px;

    @media (max-width: 1445px) {
      padding: 100px 40px;
    }

    @media (max-width: 999px) {
      padding: 31px 25px 150px 25px;
    }

    @media (max-width: 800px) {
      padding: 31px 25px 60px 25px;
    }
  }

  & .title-container {
    margin: 0 0 40px 0;

    @media (max-width: 1445px) {
      margin: 0 0 35px 0;
    }

    & h1 {
      font-size: 70px;
      line-height: 70px;

      @media (max-width: 999px) {
        font-size: 40px;
        line-height: 50px;
      }
    }

    @media (max-width: 800px) {
      margin: 0 0 10px 0;
    }
  }

  & .text-container {
    max-width: 1290px;

    & h3 {
      @media (max-width: 800px) {
        font-size: 19px;
        line-height: 24px;
      }
    }
  }
`;

const Article = styled.div`
  & .image-container {
    overflow: hidden;
    cursor: pointer;

    & img:not([aria-hidden="true"]) {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border: 39px solid #fff;
      box-sizing: border-box;

      @media (max-width: 999px) {
        border: 27px solid #fff;
      }
    }
  }

  & .title-bar {
    display: flex;
    flex-direction: row;

    & p {
      margin: 0;
    }

    & .spacer {
      margin: 0 10px;
    }
  }

  & .text-container {
    margin: 30px 0 0 0;
    padding: 0 39px;

    & .article-title {
      margin: 15px 0 20px 0;
    }

    & .article-excerpt {
      margin: 20px 0 15px 0;
    }

    @media (max-width: 999px) {
      padding: 0 27px;
    }

    @media (max-width: 800px) {
      padding: 0 25px;
    }
  }
`;

const Container = styled.div`
  padding: 90px 0 0 0;

  @media (max-width: 800px) {
    padding: 50px 0 0 0;
  }

  & .content-container {
    max-width: 1280px;
    margin: 0 auto;

    @media (max-width: 999px) {
      &.grid-6 {
        grid-row-gap: 100px;
      }
    }
  }

  & .single-article {
    &:nth-of-type(4n + 1) {
      grid-column: 1 / 4;
    }

    &:nth-of-type(4n + 2) {
      grid-column: 5 / 7;
    }

    &:nth-of-type(4n + 3) {
      grid-column: 1 / 3;
    }

    &:nth-of-type(4n + 4) {
      grid-column: 4 / 7;
    }

    @media (max-width: 999px) {
      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 3) {
        grid-column: 1 / 5;
      }

      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) {
        grid-column: 4 / 7;
      }
    }

    @media (max-width: 767px) {
      &:nth-of-type(4n + 1),
      &:nth-of-type(4n + 3),
      &:nth-of-type(4n + 2),
      &:nth-of-type(4n + 4) {
        grid-column: 1 / 7;
      }
    }
  }

  & .view-more-articles-container {
    width: 100%;
    margin: 150px 0;

    & button {
      display: block;
      margin: 0 auto;

      border: 1px solid #09184f;

      height: 50px;
      line-height: 50px;
      width: 275px;
    }
  }

  @media (max-width: 767px) {
    &.list-activities {
      padding: 0;
    }
  }
`;

const ContentContainer = styled.div`
  background-color: #fff;
`;

const Press = ({ data }) => {
  const [projectsFilter, setProjectsFilter] = useState(`all`);
  const [activeArticle, setActiveArticle] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  const [pageColor, setPageColor] = useContext(PageColor);
  const [headerColor, setHeaderColor] = useContext(HeaderColor);
  const [pageContext, setPageContext] = useContext(PageContext);

  const { ref, inView, entry } = useInView({
    trackVisibility: true,
    delay: 100,
    threshold: [0.1],
  });

  useEffect(() => {
    setPageColor(`#efefef`);
    setHeaderColor(`#efefef`);
  }, []);

  useEffect(() => {
    if (pageContext.isMenuOpen === true) {
      setHeaderColor(`#fff`);
    } else {
      if (headerColor === `#fff`) {
        setHeaderColor(`#efefef`);
      }
    }
  }, [pageContext]);

  // useEffect(() => {
  //   if (entry !== undefined) {
  //     if (
  //       entry.boundingClientRect.top <= 79 &&
  //       entry.isIntersecting &&
  //       pageContext.isMenuOpen == false
  //     ) {
  //       setHeaderColor(`#efefef`);
  //     } else {
  //       if (headerColor !== `#fff`) {
  //         setHeaderColor(`#fff`);
  //       }
  //     }
  //   }
  // }, [entry]);

  const SingleArticle = ({ article, index }) => {
    const thumbnailImage = article.items
      .filter((image) => image.image.gatsbyImageData !== null)
      .filter((image, index) => index === 0)
      .map((image, index) => (
        <GatsbyImage
          className="product-image"
          image={image.image.gatsbyImageData}
          alt={image.image.alt !== null ? image.image.alt : ``}
        />
      ));

    return (
      <Article className="single-article">
        <div
          className="image-container"
          onClick={() => {
            setActiveArticle(article);
            setIsPopupOpen(true);
          }}
        >
          {thumbnailImage}
        </div>

        <div className="text-container">
          <PrismicRichText
            field={article.primary.press_title.richText}
            components={{
              heading1: ({ children }) => (
                <h3 className="article-title">{children}</h3>
              ),
            }}
          />

          <PrismicRichText
            field={article.primary.excerpt.richText}
            components={{
              paragraph: ({ children }) => (
                <p className="article-excerpt">{children}</p>
              ),
            }}
          />

          <button
            className="uppercase small"
            onClick={() => {
              setActiveArticle(article);
              setIsPopupOpen(true);
            }}
          >
            {currentLanguage === `en` ? `See More` : `Voir Plus`}
          </button>
        </div>
      </Article>
    );
  };

  const articles = data.prismicPress.data.body.map((article, index) => (
    <SingleArticle
      index={index}
      article={article}
      key={`single_article_container_${index}_${data.prismicPress.id}_${article.id}`}
      setActiveArticle={setActiveArticle}
      setIsPopupOpen={setIsPopupOpen}
    />
  ));

  return (
    <>
      <PageSeo
        title={data.prismicPress.data.title.text}
        image={null}
        description={null}
        url={data.prismicPress.url}
      />

      <Page>
        <Introduction ref={ref} className={`articles-view`}>
          <div className="title-container">
            <PrismicRichText field={data.prismicPress.data.title.richText} />
          </div>

          <div className="text-container">
            <PrismicRichText
              field={data.prismicPress.data.text.richText}
              components={{
                paragraph: ({ children, index }) => (
                  <h3 key={`single_h3_${data.prismicPress.id}`}>{children}</h3>
                ),
              }}
            />
          </div>

          <Container className={`list-articles`}>
            <ListArticles
              articles={articles}
              setActiveArticle={setActiveArticle}
              activeArticle={activeArticle}
              setIsPopupOpen={setIsPopupOpen}
              isPopupOpen={isPopupOpen}
            />
          </Container>
        </Introduction>

        {/* <ContentContainer>{content}</ContentContainer> */}
      </Page>
    </>
  );
};

export default withPrismicPreview(Press);

export const query = graphql`
  query PressQuery($id: String, $lang: String) {
    prismicPress(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title {
          richText
          text
        }
        text {
          richText
        }
        body {
          ... on PrismicPressDataBodyPressArticle {
            id
            slice_type
            primary {
              press_title {
                richText
              }
              press_text {
                richText
              }
              excerpt {
                richText
              }
            }
            items {
              image {
                alt
                gatsbyImageData
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
