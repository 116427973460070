import React from "react";
import Select from "react-select";

// Components
import { PressPopUp } from "../press/press-popup";

export const ListArticles = ({
  articles,
  activeArticle,
  setIsPopupOpen,
  isPopupOpen,
}) => {
  return (
    <>
      <section className="content-container grid-6">{articles}</section>

      {isPopupOpen && (
        <PressPopUp
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          activeArticle={activeArticle}
        />
      )}
    </>
  );
};
